<template>
    <v-container>
        
    </v-container>
</template>

<script>
export default {
    name: 'vwFranchisePromotions'
}
</script>